.vjs-tech {
  border-radius: 0.5rem;
}

.vjs-fullscreen {
  border-radius: 0 !important;
}
.vjs-fullscreen .vjs-tech {
  border-radius: 0 !important;
}
