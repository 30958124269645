.RotateIcon {
  &:hover {
    fill: #1ab14f;
  }

  &:active {
    fill: #008b39;
    transform: scaleY(-1) rotate(-45deg);
  }
}
